@import 'src/styles/index';

.modal-window-wrapper {
  @include column-flex();

  background-color: #00000099;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  align-items: center;
  justify-content: center;
  overflow: auto;

  @include max-width(400) {
    background-color: #fff;
  }
}

.modal-window {
  @include hide-scrollbar;

  position: relative;
  overflow-y: auto;
  background-color: #fff;
  width: 98%;
  max-width: 600px;
  max-height: 98%;
  padding: 60px;
  border-radius: $default-border-radius;

  @include max-width($max-width-tablet) {
    padding: 40px;
    width: 96%;
    max-width: 520px;
  }

  @include max-width(400) {
    padding: 20px;
    width: 100%;
    max-width: initial;
    max-height: initial;
    height: 100%;
    border-radius: initial;
  }

  &__close-button {
    position: absolute;
    top: 30px;
    right: 64px;

    @include max-width($max-width-tablet) {
      top: 20px;
      right: 30px;
    }
  }
}
