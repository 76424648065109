@import 'src/styles/index';
@import 'src/styles/fontsMap';

.button {
  padding: 9px 16px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 8px;

  &--rectangle {
    border-radius: 5px;
  }

  &--round {
    border-radius: 50%;
  }

  &--square {
    aspect-ratio: 1;
  }

  &--large {
    @include size-svg(20px);
  }

  &--small {
    @include size-svg(16px);
  }

  &--center {
    justify-content: center;
  }

  &--left {
    justify-content: flex-start;
  }

  &--icon-right {
    direction: rtl;
  }

  &--styled-text {
    text-transform: uppercase;
  }

  &--fill-container {
    width: 100%;
  }

  &:disabled {
    opacity: .5;

    &:hover {
      cursor: not-allowed;
    }
  }

  &--primary {
    @include color-svg(#fff);

    border: 1px solid $green;
    color: $green;
  }

  &--secondary {
    @include color-svg($black);

    border: 1px solid $gray;
    color: $gray;
  }

  &--light {
    @include color-svg($black);

    border: 1px solid $blue;
    color: $blue;
  }

  &__icon {
    flex-shrink: 0;
    max-width: 24px;
    max-height: 24px;
  }

  &__label {
    @extend %label-2;

    white-space: nowrap;
  }
}
