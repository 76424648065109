@import 'src/styles/index';
@import 'src/styles/fontsMap';

.spicker-element {
  @include align-center-row-flex();

  justify-content: space-between;

  background-color: $white;
  border-radius: 10px;
  padding: 10px;

  &__column {
    @include column-flex();

    justify-content: flex-start;
  }

  &__input {
    border-radius: 5px;
    padding: 5px 10px;
  }

  &__text {
    @extend %caption-2;

    &--gray {
      color: $gray;
    }
  }

  &__end-wrapper {
    @include align-center-row-flex(10px);

    height: 100%;
  }

  &__delete-button {
    cursor: pointer;
  }
}
