@import 'src/styles/index';
@import 'src/styles/fontsMap';

.file-input {
  width: 100%;
  height: 50px;
  min-height: 50px;

  &__desc-file {
    @include align-center-row-flex();

    justify-content: space-between;
    border: 1px $gray solid;
    border-radius: 10px;
    box-shadow: $default-shadow;
    height: 100%;
    width: 100%;
    padding: 10px;

    svg {
        width: 24px;
        height: 24px;
    }
  }

  &__text {
    @extend %body-1;

    text-overflow: ellipsis;
    width: 70%;
  }

  &__drop-file {
    @include align-center-column-flex();

    justify-content: center;
    height: 100%;
    border: 1px $gray dashed;
    border-radius: $default-border-radius;


    svg {
        width: 24px;
        height: 24px;
    }
  }
}
