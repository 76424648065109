@import 'src/styles/index';

.home-page {
    @include align-center-row-flex();
    
    height: 100%;
    padding: 10px;
}

main {
    height: 100%;
}