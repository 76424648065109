@import 'src/styles/index';

.list-files-component {
//  overflow-y: auto;
  height: 100%;
  width: 100%;
  border-left: none;
  border-right: none;

  &__table-header {
    position: sticky;
    top: 0;
    background-color: var(--mantine-color-body);
    transition: box-shadow 150ms ease;
  
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom: rem(1px) solid
        light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-3));
    }
  }

  &__scroll {
    height: 80%;
  }
  
  &__table-scrolled {
    box-shadow: var(--mantine-shadow-sm);
  }

}
