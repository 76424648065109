@import 'src/styles/index';
@import 'src/styles/fontsMap';

.home-list-component {
  width: 100%;
  height: 100%;
  padding: 10px;

  &__wrapper {
    padding: 10px;
    background-color: #fff;
    overflow: hidden;
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }

  &__header {
    @include column-flex(10px);

    padding: 5px;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  &__buttons {
    @include align-center-row-flex(10px);
    
    width: 100%;
  }

  &__button {
    width: 100%;
  }

  &__search {
    @extend %body-1;

    width: 100%;

    input {
      @extend %body-1;
    }
  }

  &__report-icon {
    svg {
      fill: black;
    }

    &--disabled {
      svg {
        fill: $gray;
      }
    }
  }

  &__report-wrapper {
    @include align-center-row-flex(16px);
  }
}
