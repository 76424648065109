@import 'src/styles/index';
@import 'src/styles/fontsMap';

.list-file-element {
  width: 100%;
  cursor: pointer;
  padding: 5px;

  &:hover {
    background-color: $shadow-black;
  }

  &--selected {
    background-color: $shadow-green;

    &:hover {
      background-color: $shadow-green;
    }
  }

  &--block {
    background-color: $shadow-light-gray;
    cursor: default;

    &:hover {
      background-color: $shadow-light-gray;
    }
  }

  &__title {
    @extend %body-1;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
    -webkit-user-select: none;
    width: 100%;
  }

  &__title-wrapper {
    @include align-center-row-flex();
    
    justify-content: space-between;
    width: 100%;
  }

  &__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__status {
    @extend %body-1;

    &--gray {
      color: $dark-gray;
    }

    &--yellow {
      color: $yellow;
    }

    &--green {
      color: $light-green-2;
    }
  }

  &__row {
    @include align-center-row-flex(10px);
  }
}
