@import 'src/styles/index';
@import 'src/styles/fontsMap';

.add-new-file-modal {
  @include column-flex(16px);

  width: 100%;
  height: 100%;

  .modal-window {
    @include column-flex(16px);

    min-height: 90%;
    max-height: 90%;
    overflow: hidden;
  }

  &__title {
    @extend %subheader-1;
  }

  &__subtitle {
    @extend %label-2;
  }

  &__file-wrapper {
    @include align-center-row-flex();

    justify-content: space-between;
    width: 100%;
    height: 50%;
    overflow: hidden;
  }

  &__spickers-wrapper {
    overflow: hidden;
    width: 100%;
    height: 50%;
  }

  &__files {
    @include column-flex(10px);

    width: 100%;
    height: 100%;
    overflow-y: hidden;
    padding: 0 20px;
    border-right: 1px $gray solid;

    &:hover {
      overflow-y: overlay;
    }
  }

  &__tags {
    @include column-flex(10px);

    width: 100%;
    height: 100%;
    overflow-y: hidden;
    padding: 0 20px;

    &:hover {
      overflow-y: overlay;
    }
  }

  &__button {
    @extend %body-1;

    width: 60%;
    border-radius: 10px !important;
  }

  &__button-wrapper {
    @include align-center-row-flex();

    justify-content: center;

    .button {
      padding: 9px 16px;
    }
  }
}
