@import 'index';

html {
  @include min-width($max-width-tablet + 1) {
    overflow: hidden;
  }
}

body {
  color: $black;
  background-color: $white;
}

.no-wrap {
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.app-container {
  box-sizing: content-box;
  padding: 60px 20px 0;
  margin: 0 auto;
}

.gray-text {
  color: $gray;
}

.green-text {
  color: $green;
}

.light-green-text {
  color: $light-green;
}

.red-text {
  color: $red;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.text-link {
  text-decoration-skip-ink: none;
  text-decoration-line: underline;

  &:hover {
    text-decoration: none;
  }
}

.crossed-out {
  text-decoration: line-through;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration-line: underline;
}

.required::after {
  content: " *";
  color: $red;
}
