@import 'variables';
@import 'colors';

@mixin center-by-margin() {
  margin: 0 auto;
}

@mixin stretch-screen() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin button-states($base) {
  background: var(--#{$base}-bg);
}

@mixin dynamic-background($default, $hover, $active) {
  background: $default;

  &:hover {
    background: $hover;
  }

  &:active {
    background: $active;
  }
}

@mixin max-width($resolution) {
  @media (max-width: #{$resolution}px) {
    @content;
  }
}

// сильное уменьшение шрифта с уменьшением ширины экрана (для заголовков и подзаголовков)
@mixin font-strong-reduction($initial-font-size, $initial-line-height:140%) {
  $multiplier: 0.85;

  font-size: $initial-font-size;
  line-height: $initial-line-height;

  @include max-width($max-width-tablet) {
    font-size: $initial-font-size*$multiplier;
  }

  // todo: add breakpoints reduction
}

// слабое уменьшение шрифта с уменьшением ширины экрана (для более мелкого текста)
@mixin font-weak-reduction($initial-font-size, $initial-line-height) {
  $first-multiplier: 0.9;
  $second-multiplier: 0.85;

  font-size: $initial-font-size;
  line-height: $initial-line-height;

  @include max-width($max-width-tablet) {
    font-size: $initial-font-size*$first-multiplier;
    line-height: $initial-line-height*$second-multiplier;
  }
  // todo: add breakpoints reduction
}

@mixin min-width($resolution) {
  @media (min-width: #{$resolution}px) {
    @content;
  }
}

@mixin flex($gap: 0) {
  display: flex;
  gap: $gap;
}

@mixin column-flex($gap:0) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin align-center-row-flex($gap:0) {
  display: flex;
  align-items: center;
  gap: $gap;
}

@mixin align-center-column-flex($gap:0) {
  @include column-flex($gap);

  align-items: center;
}

// ограничение числа строк в блоке
@mixin line-overflow($lines-count) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-count;
  line-clamp: $lines-count;
}

@mixin color-svg($color) {
  color: $color;

  svg {
    fill: $color;
  }
}

@mixin size-svg($width, $height: $width) {
  svg {
    height: $height;
    min-height: $height;
    min-width: $width;
    width: $width;
  }
}

@mixin border-with-radius {
  border-radius: $default-border-radius;
  border: 1px solid $gray;
}

@mixin border-with-bottom-radius {
  border-radius: 0 0 $default-border-radius $default-border-radius;
  border: 1px solid $gray;
}

@mixin border-with-top-radius {
  border-radius: $default-border-radius $default-border-radius 0 0;
  border: 1px solid $gray;
}

@mixin zoom($percents) {
  transform: scale(calc($percents / 100));
  transform-origin: top left;
  width: calc(100 * 100 / $percents);
  height: calc(100 * 100 / $percents);
}

@mixin hide-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

