@import 'src/styles/index';

.search-select-wrapper .search-select {

  &__value-container {
    padding: 0;
  }

  &__input-container {
    padding: 0;
    margin: 0;
  }

  &__control {
    min-height: initial;
    border: none;
    border-radius: 0;

    &--menu-is-open {
      border: 1px solid $gray !important;
    }

    &--is-focused {
      box-shadow: none;
    }
  }

  &--error > .search-select__control {
    box-shadow: 0 4px 16px $shadow-red;
    border: 1px solid $red !important;
  }

  &__option {
    overflow: hidden;

    &--is-selected {
      color: $black;
    }
  }

  &__single-value {
    color: $gray;
  }

  &__indicators {
    display: none;
  }

  &__menu {
    overflow: hidden;
    margin: 0;
    border-radius: 0 0 $default-border-radius $default-border-radius;
    border: 1px solid $gray;
    border-top: none;
    z-index: 10;
    box-shadow: $default-shadow;
  }
}
