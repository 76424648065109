@import 'src/styles/index';
@import 'src/styles/fontsMap';

.table-header {
  padding: 0;

  &__button {
    width: 100%;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  }

  &__icon-wrapper {
    width: rem(21px);
    height: rem(21px);
    border-radius: rem(21px);
  }

  &__icon {
    width: 16px;
    height: 16px;
  }

  &__data {
    @include align-center-row-flex();

    justify-content: space-between;
  }

  &__text {
    @extend %label-2;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
    -webkit-user-select: none;
  }
}
