$white: #f1f1f1;
$black: #122425;
$light-gray: #f6f8fb;
$gray-2: #d4d6d5;
$gray: #c2c2c2;
$light-green: #7ffbd2;
$light-green-2: #23C690;
$green: #189b70;
$green-2: #168762;
$dark-green: #086546;
$red: #b62222;
$dark-gray: #7D7D7D;
$blue:#0011ff;
$dark-blue: #ccf;
$dark-sky: #559;
$yellow: #cc6;

$shadow-black: #1224254d;
$shadow-green: #12ff004d;
$shadow-gray: #2b31324d;
$shadow-light-gray: #94a9ad4d;
$shadow-red: #c800004d;
