@import 'src/styles/index';
@import 'src/styles/fontsMap';

.main-header {
  @include align-center-row-flex(10px);

  height: 60px;
  min-height: 60px;
  justify-content: space-between;
  padding: 8px 10%;
  background: $dark-blue;
  width: 100%;
  box-shadow: 4px 4px 16px 0 rgba(18, 36, 37, 0.15);
  z-index: 6;

  &__title {
    @extend %body-2;
  }
}
