@import 'mixins';

%title-1 {
  @include font-strong-reduction(73px);

  font-family: 'Inter Bold', sans-serif;
}

%title-2 {
  @include font-strong-reduction(54px);

  font-family: 'Inter Bold', sans-serif;
}

%title-3 {
  @include font-strong-reduction(48px);

  font-family: 'Inter Bold', sans-serif;
}

%subheader-1 {
  @include font-strong-reduction(25px);

  font-family: 'Inter Medium', sans-serif;
}

%subheader-2 {
  @include font-strong-reduction(35px);

  font-family: 'Inter Bold', sans-serif;
}

%body-1 {
  @include font-strong-reduction(16px);

  font-family: 'Inter Tight Regular', sans-serif;
}

%body-2 {
  @include font-strong-reduction(20px);

  font-family: 'Inter Tight Regular', sans-serif;
}

%body-3 {
  @include font-strong-reduction(25px);

  font-family: 'Inter Regular', sans-serif;
}

%body-4 {
  @include font-strong-reduction(38px);

  font-family: 'Inter Tight Medium', sans-serif;
}

%caption-1 {
  @include font-strong-reduction(11px);

  font-family: 'Inter Regular', sans-serif;
}

%caption-2 {
  @include font-strong-reduction(14px);

  font-family: 'Inter Tight Regular', sans-serif;
}

%label-1 {
  @include font-strong-reduction(15px);

  font-family: 'Inter Medium', sans-serif;
}

%label-2 {
  @include font-strong-reduction(16px);

  font-family: 'Inter Tight Medium', sans-serif;
}

%label-3 {
  @include font-strong-reduction(20px);

  font-family: 'Inter Tight Medium', sans-serif;
}

%button-label-1 {
  @include font-strong-reduction(20px);

  font-family: 'Inter Medium', sans-serif;
}

%button-label-2 {
  @include font-strong-reduction(20px);

  font-family: 'Inter Tight Bold', sans-serif;
}

%button-label-3 {
  @include font-strong-reduction(32px);

  font-family: 'Inter Tight Medium', sans-serif;
}
