@import 'src/styles/index';

.app-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__main-and-footer-wrap {
    @include column-flex;

    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  main {
    flex: 1 0;

    &.stretched {
      flex-basis: 0;
    }
  }

  footer {
    flex: 0 0 auto;
  }
}
