@import 'src/styles/index';
@import 'src/styles/fontsMap';

.add-tag-input {
  @include align-center-row-flex();

  justify-content: space-between;
  height: 50px;
  width: 100%;
  padding: 5px 10px;
  border: 1px $gray solid;
  box-shadow: $default-shadow;
  border-radius: 10px;

  svg {
    width: 24px;
    height: 24px;
  }

  &__text {
    @extend %body-1;

    text-overflow: ellipsis;
    width: 70%;
  }
}
